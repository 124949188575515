<main>
  <div class="jumbotron-constrain d-block">
    <ngx-particles 
      id="tsparticles" 
      [options]="particlesService.getOptions(isDarkMode)"
      [particlesInit]="particlesInit"
      (particlesLoaded)="particlesLoaded($event)"></ngx-particles>
    <div class="container text-center py-5">
      <h1 class="site-title">Tyler Wrenn</h1>
      <nav>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
            <span *ngIf="navbar.isNormalNavLink(navLinks)">
              <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
                (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}" role="link">{{ navLinks.navLinkName }} <span
                  class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
            </span>
            <span *ngIf="navbar.isSocialNavLink(navLinks)">
              <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)" title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
                  class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span class="sr-only">{{ navLinks.socialLinkTitle }}</span></i></a>
            </span>
            <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
              <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <section class="bg-gray-dark py-5">
    <div class="container">
      <p class="lead">Please fill out this form to inquire.</p>
      <form #inquiries="ngForm" (ngSubmit)="onSubmit(inquiries)">
        <div class="row">
          <div class="col-md mb-3">
            <label for="firstname">First Name</label>
            <input type="text" class="form-control" name="firstname" id="firstname" maxlength="50" size="30"
              placeholder="First Name" [formControl]="firstname" required="required" />
            <div class="invalid-feedback" *ngIf="(firstname.dirty || firstname.touched) && firstname.invalid">
              <p>Please enter your first name.</p>
            </div>
          </div>
          <div class="col-md mb-3">
            <label for="lastname">Last Name</label>
            <input type="text" class="form-control" name="lastname" id="lastname" maxlength="50" size="30"
              placeholder="Last Name" [formControl]="lastname" required="required" />
            <div class="invalid-feedback" *ngIf="(lastname.dirty || lastname.touched) && lastname.invalid">
              <p>Please enter your last name.</p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="email">Email</label>
          <input type="email" name="email" class="form-control" id="email" placeholder="Email" [formControl]="email"
            required="required" />
          <div class="invalid-feedback" *ngIf="(email.dirty || email.touched) && email.invalid">
            <p>Please enter your email.</p>
          </div>
        </div>
        <div class="mb-3">
          <label for="subject">Subject</label>
          <input type="text" name="subject" class="form-control" id="subject" [formControl]="formSubject"
            placeholder="Subject" required="required" />
          <div class="invalid-feedback" *ngIf="(formSubject.dirty || formSubject.touched) && formSubject.invalid">
            <p>Please enter your subject.</p>
          </div>
        </div>
        <div class="mb-3">
          <label for="message">Message</label>
          <textarea class="form-control" name="message" id="message" rows="5" [formControl]="formMessage"
            placeholder="Message" required="required"></textarea>
          <div class="invalid-feedback" *ngIf="(formMessage.dirty || formMessage.touched) && formMessage.invalid">
            <p>Please enter your message.</p>
          </div>
        </div>
        <re-captcha (resolved)="resolved($event)" siteKey="6LcNWb8UAAAAACQaA-aOwVV2GtmIuHnRvhqE9B7O"></re-captcha>
        <div id="status"></div>
        <input type="submit" class="btn btn-lg btn-primary text-white" value="Submit" />
      </form>
    </div>
  </section>
  <section class="bg-gray-dark text-center py-5">
    <div class="container">
      <hr class="border-footer" />
      <footer>
        <p class="small">Designed by Tyler Wrenn<br /><span id="pButtons"></span></p>
      </footer>
    </div>
  </section>
</main>