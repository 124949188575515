import {Component, OnInit} from '@angular/core';
import {GlobalVars} from 'src/app/global-vars';
import {TylerNavbar} from 'src/app/navbar/tylernavbar';
import {ParticlesJS} from 'src/app/particlesjs';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ParticlesService } from 'src/app/particles.service';
import { Container, Engine } from '@tsparticles/engine';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent implements OnInit {
  globals: GlobalVars;
  navbar: TylerNavbar;
  particles: ParticlesJS;
  public routeURL!: string;
  baseURL!: string;
  isDarkMode = false;

  constructor(private titleService: Title, private router: Router, public particlesService: ParticlesService) {
    this.navbar = new TylerNavbar('Not Found');
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    this.routeURL = this.router.url;
    this.baseURL = this.router.url;
    
    // Check current theme
    const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');
    this.isDarkMode = themeParse.newValue === 'ds-base--inverse';
    this.titleService.setTitle('Not Found | Tyler Wrenn');
  }

  particlesInit = async (engine: Engine): Promise<void> => {
    await this.particlesService.particlesInit(engine);
  }

  particlesLoaded(container: Container): void {
    this.particlesService.particlesLoaded(container);
  }
}
