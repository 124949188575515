import {Injectable} from '@angular/core';
import {SwUpdate, VersionEvent} from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class LogUpdateService {

  constructor(updates: SwUpdate) {
    updates.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'VERSION_READY') {
        console.log('Current version is', event.currentVersion);
        console.log('Available version is', event.latestVersion);
      } else if (event.type === 'VERSION_INSTALLATION_FAILED') {
        console.log('Failed to install version', event.version);
        console.log('Error:', event.error);
      }
    });
  }
}