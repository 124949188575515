import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {GlobalVars} from 'src/app/global-vars';
import {Router} from '@angular/router';
import { TylerNavbar } from 'src/app/navbar/tylernavbar';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ParticlesService } from 'src/app/particles.service';
import { Container, Engine } from '@tsparticles/engine';

@Component({
    selector: 'app-resume',
    templateUrl: './resume.component.html',
    styleUrls: ['./resume.component.scss'],
    standalone: false
})
export class ResumeComponent implements OnInit {
  navbar: TylerNavbar;
  globals: GlobalVars;
  baseURL!: string;
  isDarkMode = false;

  constructor(
    private titleService: Title, 
    private router: Router,
    public particlesService: ParticlesService
  ) {
    this.navbar = new TylerNavbar('My Resume');
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    this.baseURL = this.router.url;
    
    // Check current theme
    const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');
    this.isDarkMode = themeParse.newValue === 'ds-base--inverse';
    
    this.titleService.setTitle('Resume | Tyler Wrenn');
  }
  
  particlesInit = async (engine: Engine): Promise<void> => {
    await this.particlesService.particlesInit(engine);
  }

  particlesLoaded(container: Container): void {
    this.particlesService.particlesLoaded(container);
  }
}