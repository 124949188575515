<main>
  <div class="jumbotron-constrain d-block">
    <ngx-particles 
      id="tsparticles" 
      [options]="particlesService.getOptions(isDarkMode)"
      [particlesInit]="particlesInit"
      (particlesLoaded)="particlesLoaded($event)"></ngx-particles>
    <div class="container text-center py-5">
      <h1 class="site-title">Tyler Wrenn</h1>
      <nav>
        <ul class="nav nav-pills justify-content-center">
          <li class="nav-item" *ngFor="let navLinks of navbar.getNavbar">
            <span *ngIf="navbar.isNormalNavLink(navLinks)">
              <a class="{{ navbar.getNavButtonClasses(navLinks) }}"
                (click)="globals.navigate(baseURL, navLinks.navLinkTarget)" tabindex="{{ navLinks.tabIndex }}"
                role="link">{{ navLinks.navLinkName }} <span
                  class="sr-only">{{ navbar.getAriaAttribute(navLinks) }}</span></a>
            </span>
            <span *ngIf="navbar.isSocialNavLink(navLinks)">
              <a class="{{ navbar.navButtonClasses }}" (click)="globals.goToSocialLink(navLinks.socialLinkTarget)"
                title="{{ navLinks.socialLinkTitle }}" tabindex="{{ navLinks.tabIndex }}" role="link"><i
                  class="{{ navLinks.socialLinkIcon }}" role="img" aria-hidden="true"><span
                    class="sr-only">{{ navLinks.socialLinkTitle }}</span></i></a>
            </span>
            <span *ngIf="navbar.isDarkModeToggleLink(navLinks)">
              <theme-toggle tabindex="{{ navLinks.tabIndex }}" title="Toggle Dark Mode"></theme-toggle>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="bg-gray-dark">
    <section class="pt-5">
      <div class="container">
        <h2 class="text-center">This area is under construction!</h2>
        <h2 class="text-center">Education</h2>
      </div>
    </section>

    <section class="py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-10">
            <h4><span class="fw-bold">George Mason University</span>&nbsp;<small
                class="ds-u-color--base-flip">Fairfax, VA</small>
            </h4>
            
            <ul class="lead">
              <li>Bachelor of Science, Information Technology, Concentration in Web Development and Multimedia, Volgenau School of Engineering</li>
            </ul>
          </div>

          <div class="col-md-2 text-end">
            <h4>December 2020</h4>
          </div>



        </div>



      </div>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">ECPI University</span>&nbsp;<small



                class="ds-u-color--base-flip">Richmond, VA</small></h4>



            <ul class="lead">



              <li>Dual enrolled during senior year in high school</li>



              <li>Completed the Web Development/Programming II course to further my expertise in programming</li>



            </ul>



          </div>



          <div class="col-md-2 text-end">



            <h4>June 2016</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Work Experience</h2>



      <h4 class="text-center">Holds an Active Public Trust Clearance</h4>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Data Engineering Sr Analyst</span>&nbsp;<small><a class="ds-u-color--base-flip"



              href="https://www.accenturefederal.com/" target="_blank">Accenture Federal Services</a>, Arlington, VA</small></h4>



          </div>



          <div class="col-md-2">



            <h4>July 2022 &mdash; Present</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Technical Analyst</span>&nbsp;<small><a class="ds-u-color--base-flip"



                  href="https://www.longevityconsulting.com/" target="_blank">Longevity Consulting</a>, Washington,



                D.C.</small></h4>



          </div>



          <div class="col-md-2">



            <h4>February 2021 &mdash; July 2022</h4>



          </div>



        </div>



        <p class="lead">U.S. Department of Education</p>



        <ul class="lead">



          <li>Successfully moved a comprehensive website for the National Technical Assistance Center for the Education



            of Youth Who Are Neglected, Delinquent, or At-Risk from development to production, meeting strict deadlines.



          </li>



          <li>Created a bot using Robot Process Automation that sorted over 5,000 files in a file system, cutting the



            processing time by 50%.</li>



          <li>Spearheaded and resolved critical project management workflows that could have resulted in over $110k in



            accrued penalties.</li>



          <li>Supported in the development of a proof-of-concept automation using RPA, AI, and OCR technology for grants



            management processing which yielded over 600% decrease in processing time with 100% of all errors captured.



          </li>



        </ul>



        <p class="lead">Department of Transportation Canada</p>



        <ul class="lead">



          <li>Conducted a comprehensive current state assessment on the North American Fatigue Management Program's



            transparency website which resulted in a full website redesign that yielded higher quality, security, and



            accessibility for end users.</li>



        </ul>



        <p class="lead">Internal Projects</p>



        <ul class="lead">



          <li>Successfully migrated Longevity's new corporate website from development to production as well as set up



            Search Engine Optimization features, which has yielded an increase in customer outreach by over 20%.</li>



        </ul>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Certifications</h2>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4 class="fw-bold">UiPath Certified Advanced RPA Developer (UiARD) <img



                src="assets/images/uipath-certified-advanced-rpa-developer.png" class="img-fluid w-10" /></h4>



          </div>



          <div class="col-md-2">



            <h4>Issued August 2021</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Technical Skills</h2>



    </section>



    <section class="pt-5 pb-2">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-2">



            <h4>Applications and Platforms</h4>



          </div>



          <div class="col-md-10">



            <ul class="list-inline h4">



              <li class="list-inline-item resume-badge">Amazon Web Services</li>



              <li class="list-inline-item resume-badge">Adobe Dreamweaver</li>



              <li class="list-inline-item resume-badge">Adobe Photoshop</li>



              <li class="list-inline-item resume-badge">Notepad++</li>



              <li class="list-inline-item resume-badge">Microsoft Office</li>



              <li class="list-inline-item resume-badge">phpMyAdmin</li>



              <li class="list-inline-item resume-badge">File Transfer Protocol



                (FTP)



              </li>



              <li class="list-inline-item resume-badge">SSH File Transfer



                Protocol



                (SFTP)



              </li>



              <li class="list-inline-item resume-badge">Debian Linux (e.g.



                Ubuntu)



              </li>



              <li class="list-inline-item resume-badge">CentOS Linux</li>



              <li class="list-inline-item resume-badge">Red Hat Enterprise Linux</li>



              <li class="list-inline-item resume-badge">macOS</li>



              <li class="list-inline-item resume-badge">Windows</li>



            </ul>



          </div>



        </div>



      </div>



    </section>



    <section class="py-2">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-2">



            <h4>Coding Languages</h4>



          </div>



          <div class="col-md-10">



            <div class="row align-items-center">



              <div class="col-md-2">



                <h4>Proficient in:</h4>



              </div>



              <div class="col-md-10">



                <ul class="list-inline h4">



                  <li class="list-inline-item resume-badge">HTML</li>



                  <li class="list-inline-item resume-badge">CSS</li>



                  <li class="list-inline-item resume-badge">Bootstrap</li>



                  <li class="list-inline-item resume-badge">MySQL</li>



                  <li class="list-inline-item resume-badge">JavaScript</li>



                  <li class="list-inline-item resume-badge">jQuery</li>



                  <li class="list-inline-item resume-badge">PHP</li>



                </ul>



              </div>



              <div class="col-md-2">



                <h4>Moderately Proficient in:</h4>



              </div>



              <div class="col-md-10">



                <ul class="list-inline h4">



                  <li class="list-inline-item resume-badge">C#</li>



                  <li class="list-inline-item resume-badge">Visual Basic</li>



                  <li class="list-inline-item resume-badge">ASP.NET MVC</li>



                  <li class="list-inline-item resume-badge">MariaDB</li>



                  <li class="list-inline-item resume-badge">Java</li>



                </ul>



              </div>



            </div>



          </div>



        </div>



      </div>



    </section>



    <section class="py-2">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-2">



            <h4>Content Management Systems</h4>



          </div>



          <div class="col-md-10">



            <ul class="list-inline h4">



              <li class="list-inline-item resume-badge">WordPress</li>



              <li class="list-inline-item resume-badge">Drupal</li>



              <li class="list-inline-item resume-badge">Squarespace</li>



              <li class="list-inline-item resume-badge">cPanel</li>



              <li class="list-inline-item resume-badge">Other Website Builders



              </li>



            </ul>



          </div>



        </div>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Other Work Experience</h2>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Contract Website Designer</span>&nbsp;<small>Patient



                Experience and End Results Orthopedic Clinic (PEERClinic), Fairfax, VA</small></h4>



            <ul class="lead">



              <li>Redesigned the company website (<a class="ds-u-color--base-flip" href="https://peerclinic.com/"



                  rel="noopener noreferrer" target="_blank">https://peerclinic.com/</a>)



              </li>



              <ul>



                <li>Designed the company logo</li>



              </ul>



              <li>Replaced the Electronic Medical Record system with an open-sourced medical record system called Open



                Electronic Medical Records (OpenEMR)



              </li>



              <ul>



                <li>Provided continuous support to ensure the system is HIPPA and ONC (Office of the National



                  Coordinator



                  for Health Information Technology) compliant



                </li>



              </ul>



            </ul>



          </div>



          <div class="col-md-2">



            <h4>January 2019 &mdash; August 2020</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Part-Time Deli Clerk</span>&nbsp;<small>Publix, Henrico,



                VA</small></h4>



            <ul class="lead">



              <li>Sliced deli meat and cheeses as well as cooked hot foods</li>



              <li>Delivered effective customer engagement to provide quality of the order and accuracy of the detail



              </li>



            </ul>



          </div>



          <div class="col-md-2">



            <h4>June 2018 &mdash; August 2018</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Website Designer</span>&nbsp;<small>Trolley House



                Refreshments,



                Richmond, VA</small></h4>



            <ul class="lead">



              <li>Overhauled the company's main website layout and content from scratch using Dreamweaver, making the



                content more user-friendly to customers without the use of website creation software.



              </li>



              <li>Assisted in the web analytics and Search Engine Optimization of the company's main website to further



                expand the audience of their customers.



              </li>



            </ul>



          </div>



          <div class="col-md-2">



            <h4>March 2016 &mdash; August 2016</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Volunteer Experience</h2>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Project Mentor</span><br /><small><a class="ds-u-color--base-flip"



                  href="https://www.open-emr.org/" rel="noopener noreferrer" target="_blank">Open



                  Electronic Medical Records (OpenEMR)</a>, Modernizing UI, Google



                Summer



                of Code (GSoC)</small></h4>



            <ul class="lead">



              <li>Took a leadership role for an open-sourced platform funded by its backers and sponsors</li>



              <li>Helped guide a student to redesign OpenEMR's User Interface, remove outdated code, and future-proof



                the



                codebase



              </li>



              <li>Used effective interactions between individuals and administrators during GSoC</li>



            </ul>



          </div>



          <div class="col-md-2">



            <h4>February 2020 &mdash; August 2020</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="pt-5">



      <h2 class="text-center">Side Projects</h2>



    </section>



    <section class="py-5">



      <div class="container">



        <div class="row align-items-center">



          <div class="col-md-10">



            <h4><span class="fw-bold">Company Website</span>&nbsp;<small><a class="ds-u-color--base-flip"



                  href="https://peersrva.com/" rel="noopener noreferrer" target="_blank">Program for the



                  Education and Enrichment of Relational Skills (PEERS RVA)</a>, Richmond,



                VA</small>



            </h4>



            <ul class="lead">



              <li>Executed and completed client needs for specific deliverables for student communication</li>



              <li>Provided weekly updates to website progression</li>



              <li>Created testing environment to ensure connectivity and user feasibility</li>



            </ul>



          </div>



          <div class="col-md-2">



            <h4>Completed August 2019</h4>



          </div>



        </div>



      </div>



    </section>



    <section class="py-5">



      <div class="container">



        <hr class="border-footer" />



        <footer class="text-center">



          <p class="small">Designed by Tyler Wrenn<br />All copyrights are for their respected



            owners.<br /><span id="pButtons"></span></p>



        </footer>



      </div>



    </section>



  </div>



</main>



