import {Component, OnInit} from '@angular/core';
import {ParticlesJS} from '../particlesjs';
import {GlobalVars} from '../global-vars';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TylerNavbar} from '../navbar/tylernavbar';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-testing-area',
    templateUrl: './testing-area.component.html',
    styleUrls: ['./testing-area.component.scss'],
    standalone: false
})
export class TestingAreaComponent implements OnInit {
  navbar: TylerNavbar;
  particles: ParticlesJS;
  globals: GlobalVars;
  baseURL!: string;

  constructor(private titleService: Title, private router: Router, private meta: Meta) {
    this.navbar = new TylerNavbar();
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    this.baseURL = this.router.url;
    setTimeout(() => {
      this.particles.loadTSParticles();
      this.particles.createParticlesButtons();
    }, 1);
    this.titleService.setTitle('Testing Area | Tyler Wrenn');
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.meta.removeTag('name="revisit-after"');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
  }

}
