import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { ParticlesService } from './particles.service';
import { Container, Engine } from '@tsparticles/engine';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'tyler-wrenn-website';
  modesLoaded = false;
  isDarkMode = false;
  
  constructor(
    private renderer: Renderer2,
    public particlesService: ParticlesService
  ) {}

  ngOnInit(): void {
    const themeMode = localStorage.getItem('mode-class');
    if (themeMode === null || themeMode === undefined) {
      if (!environment.production) {
        console.log('Set the mode-class from local storage');
      }

      if (window.matchMedia('(prefers-color-scheme: dark)').media === 'not all') {
        // Set to light mode if it isn't supported in-browser
        localStorage.setItem(
          'mode-class',
          JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base' })
        );
        this.isDarkMode = false;
      } else {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        if (!mediaQuery || mediaQuery.matches) {
          localStorage.setItem(
            'mode-class',
            JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base--inverse' })
          );
          this.isDarkMode = true;
        } else {
          localStorage.setItem(
            'mode-class',
            JSON.stringify({ oldValue: 'ds-base--inverse', newValue: 'ds-base' })
          );
          this.isDarkMode = false;
        }
      }
    } else {
      const themeParse = JSON.parse(themeMode);
      this.isDarkMode = themeParse.newValue === 'ds-base--inverse';
    }
    
    this.modesLoaded = true;

    if (!environment.production) {
      console.log('Tyler Wrenn\'s website loaded.');
    }

    this.setThemeMode();
    
    // Listen for theme changes
    window.addEventListener('storage', (event) => {
      if (event.key === 'mode-class') {
        this.setThemeMode();
        const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');
        this.isDarkMode = themeParse.newValue === 'ds-base--inverse';
        this.particlesService.refreshParticles(this.isDarkMode);
      }
    });
  }

  setThemeMode(): void {
    const themeParse = JSON.parse(localStorage.getItem('mode-class') || '{}');
    if (themeParse.oldValue) {
      if (!environment.production) {
        console.log('Old Value: ' + themeParse.oldValue);
      }
      this.renderer.removeClass(document.body, themeParse.oldValue);
    }

    if (!environment.production) {
      console.log('New Value: ' + themeParse.newValue);
    }
    this.renderer.addClass(document.body, themeParse.newValue);
  }
  
  particlesInit = async (engine: Engine): Promise<void> => {
    await this.particlesService.particlesInit(engine);
  }

  particlesLoaded(container: Container): void {
    this.particlesService.particlesLoaded(container);
  }
}