import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { ParticlesService } from '../particles.service';

@Component({
    selector: 'theme-toggle',
    templateUrl: './theme-toggle.component.html',
    styleUrls: ['./theme-toggle.component.scss'],
    standalone: false
})
export class ThemeToggleComponent implements OnInit {
  theme!: string;
  oldTheme!: string;

  constructor(
    private appComponent: AppComponent,
    private particlesService: ParticlesService
  ) {}

  ngOnInit(): void {
    if (this.appComponent.modesLoaded) {
      this.theme = JSON.parse(localStorage.getItem('mode-class') || '{}').newValue;
    }
  }

  toggleTheme() {
    this.oldTheme = this.theme;
    if (this.oldTheme === 'ds-base') {
      this.theme = 'ds-base--inverse';
    } else {
      this.theme = 'ds-base';
    }
    
    if (!environment.production) {
      console.log('Old Theme: ' + this.oldTheme);
      console.log('New Theme: ' + this.theme);
    }
    
    localStorage.removeItem('mode-class');
    localStorage.setItem('mode-class', JSON.stringify({ oldValue: this.oldTheme, newValue: this.theme }));
    this.appComponent.setThemeMode();
    
    // Update particles based on theme
    const isDarkMode = this.theme === 'ds-base--inverse';
    this.particlesService.refreshParticles(isDarkMode);
    
    // Dispatch storage event to notify other components
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'mode-class',
      newValue: JSON.stringify({ oldValue: this.oldTheme, newValue: this.theme })
    }));
  }
}