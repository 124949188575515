import { loadBaseMover } from "@tsparticles/move-base";
import { loadCircleShape } from "@tsparticles/shape-circle";
import { loadColorUpdater } from "@tsparticles/updater-color";
import { loadHexColorPlugin } from "@tsparticles/plugin-hex-color";
import { loadHslColorPlugin } from "@tsparticles/plugin-hsl-color";
import { loadOpacityUpdater } from "@tsparticles/updater-opacity";
import { loadOutModesUpdater } from "@tsparticles/updater-out-modes";
import { loadRgbColorPlugin } from "@tsparticles/plugin-rgb-color";
import { loadSizeUpdater } from "@tsparticles/updater-size";
export async function loadBasic(engine, refresh = true) {
  engine.checkVersion("3.8.1");
  await loadHexColorPlugin(engine, false);
  await loadHslColorPlugin(engine, false);
  await loadRgbColorPlugin(engine, false);
  await loadBaseMover(engine, false);
  await loadCircleShape(engine, false);
  await loadColorUpdater(engine, false);
  await loadOpacityUpdater(engine, false);
  await loadOutModesUpdater(engine, false);
  await loadSizeUpdater(engine, false);
  await engine.refresh(refresh);
}