import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {ResumeComponent} from './pages/resume/resume.component';
import {InquiriesComponent} from './pages/inquiries/inquiries.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HomeComponent} from './pages/home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RecaptchaModule} from 'ng-recaptcha-2';
import {CommonModule} from '@angular/common';
import { NgxParticlesModule } from '@tsparticles/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LogUpdateService} from './log-update.service';
import {CheckForUpdateService} from './check-for-update.service';
import {LogoComponent} from './pages/logo/logo.component';
import {TestingAreaComponent} from './testing-area/testing-area.component';
import { ThemeToggleComponent } from './theme-toggle/theme-toggle.component';

@NgModule({ declarations: [
        AppComponent,
        PortfolioComponent,
        ResumeComponent,
        InquiriesComponent,
        HomeComponent,
        NotFoundComponent,
        LogoComponent,
        TestingAreaComponent,
        ThemeToggleComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        NgxGoogleAnalyticsModule.forRoot('G-XBVC2MBN1M'),
        NgxGoogleAnalyticsRouterModule,
        NgbModule,
        NgxParticlesModule,
        ReactiveFormsModule,
        RecaptchaModule,
        FormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })], providers: [
        LogUpdateService,
        CheckForUpdateService,
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule {
}