import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {InquiriesComponent} from './pages/inquiries/inquiries.component';
import {ResumeComponent} from './pages/resume/resume.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {HomeComponent} from './pages/home/home.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {PagePaths} from './pagepaths';
import {LogoComponent} from './pages/logo/logo.component';
import {TestingAreaComponent} from './testing-area/testing-area.component';

export const routes: Routes = [
  {path: PagePaths.paths.home, component: HomeComponent},
  {path: PagePaths.paths.resume, component: ResumeComponent},
  {path: PagePaths.paths.portfolio, component: PortfolioComponent},
  {path: PagePaths.paths.inquiries, component: InquiriesComponent},
  {path: PagePaths.paths.logo, component: LogoComponent},
  {path: PagePaths.paths.testingEnvironment, component: TestingAreaComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
