export class NavLink {
  public navLinkName!: string;
  public navLinkTarget!: string;
  public isNavLinkActive!: boolean;
  public socialLinkIcon!: string;
  public socialLinkTarget!: string;
  public socialLinkTitle!: string;
  public tabIndex!: number;

  constructor(public linkTabIndex: number) {
    this.tabIndex = linkTabIndex;
  }
}

export class NormalNavLink extends NavLink {
  constructor(linkName: string, linkTarget: string, isLinkActive: boolean, public linkTabIndex: number) {
    super(linkTabIndex);
    this.navLinkName = linkName;
    this.navLinkTarget = linkTarget;
    this.isNavLinkActive = isLinkActive;
    this.tabIndex = linkTabIndex;
  }
}

export class SocialLink extends NavLink {
  constructor(linkTitle: string, linkIcon: string, linkTarget: string, public linkTabIndex: number) {
    super(linkTabIndex);
    this.socialLinkTitle = linkTitle;
    this.socialLinkIcon = linkIcon;
    this.socialLinkTarget = linkTarget;
    this.tabIndex = linkTabIndex;
  }
}

export class DarkModeToggleLink extends NavLink {
  constructor(public linkTabIndex: number) {
    super(linkTabIndex);
  }
}