import { downloadSvgImage, loadImage } from "./Utils.js";
import { ImageDrawer } from "./ImageDrawer.js";
import { ImagePreloaderPlugin } from "./ImagePreloader.js";
import { errorPrefix } from "@tsparticles/engine";
import { loadGifImage } from "./GifUtils/Utils.js";
const extLength = 3;
function addLoadImageToEngine(engine) {
  if (engine.loadImage) {
    return;
  }
  engine.loadImage = async data => {
    if (!data.name && !data.src) {
      throw new Error(`${errorPrefix} no image source provided`);
    }
    if (!engine.images) {
      engine.images = [];
    }
    if (engine.images.find(t => t.name === data.name || t.source === data.src)) {
      return;
    }
    try {
      const image = {
        gif: data.gif ?? false,
        name: data.name ?? data.src,
        source: data.src,
        type: data.src.substring(data.src.length - extLength),
        error: false,
        loading: true,
        replaceColor: data.replaceColor,
        ratio: data.width && data.height ? data.width / data.height : undefined
      };
      engine.images.push(image);
      let imageFunc;
      if (data.gif) {
        imageFunc = loadGifImage;
      } else {
        imageFunc = data.replaceColor ? downloadSvgImage : loadImage;
      }
      await imageFunc(image);
    } catch {
      throw new Error(`${errorPrefix} ${data.name ?? data.src} not found`);
    }
  };
}
export async function loadImageShape(engine, refresh = true) {
  engine.checkVersion("3.8.1");
  addLoadImageToEngine(engine);
  const preloader = new ImagePreloaderPlugin(engine);
  await engine.addPlugin(preloader, refresh);
  await engine.addShape(new ImageDrawer(engine), refresh);
}