import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ParticlesJS} from 'src/app/particlesjs';
import {GlobalVars} from 'src/app/global-vars';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: false
})
export class LogoComponent implements OnInit {
  globals: GlobalVars;
  particles: ParticlesJS;

  constructor(private titleService: Title, private router: Router) {
    this.particles = new ParticlesJS();
    this.globals = new GlobalVars(router);
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bodySelector = document.querySelector('body')!;
    bodySelector.style.backgroundColor = '#fff';
    setTimeout(() => {
      this.particles.loadWhiteTSParticles();
    }, 1);

    this.titleService.setTitle('Tyler Wrenn');
  }

}
