import { Injectable } from '@angular/core';
import { Container, Engine, MoveDirection, OutMode } from '@tsparticles/engine';
import { loadSlim } from '@tsparticles/slim';

@Injectable({
  providedIn: 'root'
})
export class ParticlesService {
  private container: Container | undefined;
  private enabled = true;

  constructor() {
    // Check for reduced motion preference
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (mediaQuery && mediaQuery.matches) {
      this.enabled = false;
    }
  }

  async particlesInit(engine: Engine): Promise<void> {
    if (!this.enabled) return;
    await loadSlim(engine);
  }

  particlesLoaded(container: Container): void {
    this.container = container;
  }

  getOptions(isDarkMode: boolean) {
    if (!this.enabled) {
      // Return minimal config with no particles when disabled
      return {
        particles: {
          number: {
            value: 0
          }
        }
      };
    }

    return {
      background: {
        color: {
          value: 'transparent'
        }
      },
      fpsLimit: 60,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'push'
          },
          onHover: {
            enable: true,
            mode: 'repulse'
          },
          resize: {
            enable: true,
            delay: 0,
            factor: 1
          }
        },
        modes: {
          push: {
            quantity: 4
          },
          repulse: {
            distance: 200,
            duration: 0.4
          }
        }
      },
      particles: {
        color: {
          value: isDarkMode ? '#ffffff' : '#495057'
        },
        links: {
          color: isDarkMode ? '#ffffff' : '#495057',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.bounce
          },
          random: false,
          speed: 2,
          straight: false
        },
        number: {
          density: {
            enable: true,
            area: 800
          },
          value: 80
        },
        opacity: {
          value: 0.5
        },
        shape: {
          type: 'circle'
        },
        size: {
          value: { min: 1, max: 5 }
        }
      },
      detectRetina: true,
      fullScreen: {
        enable: true,
        zIndex: -1,
        container: 'tsparticles'
      }
    };
  }

  refreshParticles(isDarkMode: boolean): void {
    if (!this.enabled || !this.container) return;
    
    // Update particle colors without destroying container
    if (this.container.actualOptions.particles) {
      const color = isDarkMode ? '#ffffff' : '#495057';
      if (this.container.actualOptions.particles.color) {
        this.container.actualOptions.particles.color.value = color;
      }
      
      if (this.container.actualOptions.particles.links) {
        // Type assertion to avoid TypeScript error
        (this.container.actualOptions.particles.links as any).color = color;
      }
      
      this.container.refresh();
    }
  }

  toggleParticles(): void {
    if (!this.container) return;
    
    if (this.container.animationStatus) {
      this.container.pause();
    } else {
      this.container.play();
    }
  }

  isEnabled(): boolean {
    return this.enabled;
  }
}